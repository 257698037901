import React, { Component } from 'react'
import styled from 'styled-components'
import { Section, Container, Flex } from '../global'
import Item from './item.js'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import fuzjePrzejecia from '../../images/icons/fuzjePrzejecia.svg'
import prawoKolejowe from '../../images/icons/prawoKolejowe.svg'
import prawoSpolek from '../../images/icons/prawoSpolek.svg'
import rynekKapitalowy from '../../images/icons/rynekKapitalowy.svg'
import { SPECIALISATIONS } from '../const'
class Services extends Component {
	state = {
		displayDescription: 'Prawo spółek',
	}

	render() {
		return (
			<Section id="services">
				<Container>
					<h4>specjalizacje</h4>
					<Info>
						<p>
							Nasza Kancelaria specjalizuje się między innymi w prawie korporacyjnym (w tym transakcjach M&A), prawie rynku kapitałowego, prawie
							bankowym, prawie nieruchomości i robotach budowlanych, negocjacji umów, prawie energetycznym, postępowaniach sądowych i
							administracyjnych, prawie karnym, podatkach, prawie lotniczym, prawie pracy oraz prawie zamówień publicznych.
						</p>
						<p>
							Ponadto posiadamy wieloletnie doświadczenie w szeroko pojętym prawie zbrojeniowym, w tym w offsecie przemysłowym realizowanym na
							podstawie ustawy o niektórych umowach zawieranych w związku z realizacją zamówień o podstawowym znaczeniu dla bezpieczeństwa państwa,a
							nadto w prawie kolejowym w tym w rozstrzyganiu takich kwestii jak dopuszczalność i możliwość różnicowania cen biletów kolejowych w
							zależności od kanału dystrybucji (tematyka obejmująca swoim zakresem szeroko pojęte prawo kolejowe, prawo cywilne oraz prawo
							antymonopolowe).
						</p>
					</Info>
					<Content>
						<Column>
						{this.state.displayDescription == 'Fundacje rodzinne' ? (
								<>
									{this.state.displayDescription == 'Fundacje rodzinne' ? (
										<>
											<h5>{this.state.displayDescription}</h5>
										
										</>
									) : null}
								</>
							) : null}
							{this.state.displayDescription == 'Prawo spółek' ? (
								<>
									{this.state.displayDescription == 'Prawo spółek' ? (
										<>
											<h5>{this.state.displayDescription}</h5>
											<p>
												iditate modi, eius fuga dolorem nisi repellendus ducimus voluptates laboriosam sit accusantium, illo rem eum veniam quis
												repellat consequuntur dicta voluptas aliquid. Veritatis possimus quod praesentium, nobis quae laborum?
											</p>
										</>
									) : null}
								</>
							) : null}
							{this.state.displayDescription == 'Fuzje i przejęcia' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>
										Kompleksowa pomoc prawna w przeprowadzaniu procesu połączenia, podziału lub przekształcenia spółki ze szczególnym uwzględnieniem
										uwarunkowań podatkowych i organizacyjnych. Fachowe doradztwo w wyborze najbardziej optymalnej formy prowadzenia działalności.
									</p>
								</>
							) : null}

							{this.state.displayDescription == 'Rynek kapitałowy' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>
										Fachowa pomoc prawna związana z funkcjonowaniem szeroko pojętego rynku kapitałowego. Kancelaria posiada szerokie doświadczenie w
										ramach wielu transakcji realizowanych tak na rynku krajowym (dopuszczenie i wprowadzenie do obrotu akcji na rynku regulowanym
										GPW), jak i zagranicznym (Deutsche Börse AG – Niemcy; Borsa Italiana S.p.A. – Włochy; NASDAQ – USA).
									</p>
								</>
							) : null}

							{this.state.displayDescription == 'Prawo kolejowe' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>
										Jedną z unikatowych specjalizacji Kancelarii jest także doradztwo spółkom kolejowym w zakresie obowiązujących je regulacji, tak na
										gruncie krajowym, jak i europejskim. Pomoc prawna Kancelarii obejmuje m.in. pierwsze w Polsce opinie i rozstrzygnięcia w zakresie
										dopuszczalności i możliwości różnicowania cen biletów kolejowych w przewozach pasażerskich w zależności od kanału dystrybucji
										(dopuszczalność stosowania innych cen biletów w ramach tradycyjnych kanałów sprzedaży [kasy biletowe] i elektronicznych kanałów
										sprzedaży [Internet, aplikacje na telefon, automaty biletowe]). Doradztwo Kancelarii obejmuje m.in. regulacje z zakresu:
									</p>
								</>
							) : null}

							{/* {this.state.displayDescription ==
              "Tzw. Kredyty (pseudo)frankowe" ? (
                <>
                  <h5>{this.state.displayDescription}</h5>
                  <p>
                    Kancelaria prowadzi sprawy umów kredytów powiązanych z
                    walutami obcymi, w szczególności z CHF, USD, EUR oraz JPY.
                    Dotyczy to zarówno tzw. umów indeksowanych, denominowanych
                    oraz mieszanych.
                  </p>
                  <p>
                    W ramach swojej argumentacji Kancelaria zawsze w pierwszej
                    kolejności podnosi argumenty na nieważność umów kredytowych
                    (zarówno indeksowanych, jak i denominowanych), co wynika z
                    licznych wad prawnych o wręcz fundamentalnym znaczeniu.
                    Dopiero jako roszczenie ewentualne podnoszone są argumenty o
                    bezskuteczności postanowień umownych zawartych w umowie
                    (tzw. klauzule abuzywne/niedozwolone postanowienia umowne).{" "}
                  </p>
                </>
              ) : null} */}

							{this.state.displayDescription == 'Nieruchomości i prawo budowlane' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}

							{this.state.displayDescription == 'Umowy – kontrakty' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}

							{this.state.displayDescription == 'Prawo pracy' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}

							{this.state.displayDescription == 'Energetyka' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}

							{this.state.displayDescription == 'Postępowania sądowe i administracyjne' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}

							{this.state.displayDescription == 'Podatki' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}

							{this.state.displayDescription == 'Prawo zamówień publicznych' ? (
								<>
									<h5>{this.state.displayDescription}</h5>
									<p>...</p>
								</>
							) : null}
						</Column>

						<Column>
							<Items>
								{SPECIALISATIONS.filter((item) => item.size === null).map((item, index) => {
									return (
										<Item
											key={index}
											title={item.title}
											icon={item.icon}
											description={item.description}
											url={item.url}
											size={item.size}
											// handleMouseMove={this.handleItemMouseMove}
										/>
									)
								})}
							</Items>
							<ItemsSmall>
								{SPECIALISATIONS.filter((item) => item.size === 'small').map((item, index) => {
									return (
										<Item
											key={index}
											title={item.title}
											icon={item.icon}
											description={item.description}
											url={item.url}
											size={item.size}
											// handleMouseMove={this.handleItemMouseMove}
										/>
									)
								})}
							</ItemsSmall>
						</Column>
					</Content>
				</Container>
			</Section>
		)
	}
}

export default Services
const Info = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto 30px;
	p {
		padding: 0 0px;
		&:first-child {
			width: 40%;
			margin-right: 20px;
			/* font-weight: ${(props) => props.theme.font.weight.medium}; */
		}
		&:last-child {
			width: 60%;
		}
	}
	@media (max-width: ${(props) => props.theme.screen.lg}) {
		width: 100%;
	}
	@media (max-width: ${(props) => props.theme.screen.md}) {
		flex-direction: column;
		p {
			&:first-child {
				width: 100%;
				font-weight: ${(props) => props.theme.font.weight.medium};
			}
			&:last-child {
				width: 100%;
			}
		}
	}
`
const Dot = styled.li`
	line-height: 1.1;

	&::before {
		content: '⦿';
		padding-right: 8px;
		color: ${(props) => props.theme.color.accent};
	}
`

const Items = styled(Flex)`
	width: 100%;
	justify-content: center;
	flex-wrap: wrap;
	padding: 1px;
`
const ItemsSmall = styled(Flex)`
	width: 100%;

	justify-content: center;
	flex-wrap: wrap;
	padding: 1px;
`
const Content = styled(Flex)`
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
`
const Column = styled(Flex)`
	:first-child {
		display: none;
	}
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-basis: 100%;
	z-index: 1;
`
