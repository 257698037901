import React from 'react'
import styled from 'styled-components'
import { Flex, Button, Dot } from '../global'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'
import { ADDRESS } from '../../components/const'
import arrow from '../../images/arrow.png'
const query = graphql`
	query {
		file(relativePath: { eq: "career.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1200, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

const Career = () => {
	const { file } = useStaticQuery(query)

	return (
		<>
			<BackgroundImage Tag="section" fluid={file.childImageSharp.fluid} id="career">
				<Content>
					<div></div>
					<Text>
						<h4>Kariera</h4>
						<p>
							<strong> MWDK / Waś, Krajewski & Wspólnicy </strong>z siedzibą w Warszawie przy ulicy Nowy Świat 6/12 podejmie współpracę ze studentami,
							magistrami prawa, aplikantami lub radcami prawnymi / adwokatami.
						</p>
						<List>
							<Dot>
								Zgłoszenia prosimy przesyłać na adres e-mail:
								<a href={`mailto:${ADDRESS.mail}`}>{ADDRESS.mail}</a>
							</Dot>
							<Dot>W tytule maila prosimy wpisać „REKRUTACJA”;</Dot>
							<Dot>W treści CV / Resume oraz e-maila prosimy o zamieszczenie następującej zgody na przetwarzanie danych osobowych:</Dot>
						</List>
						<Rodo>
							„Wyrażam zgodę na przetwarzanie moich danych osobowych przez Administratora – MWDK / Waś, Krajewski & Wspólnicy z siedzibą w Warszawie
							(00-400) przy ul. Nowy Świat 6/12 - zawartych w złożonej dokumentacji w celu przeprowadzenia postępowania rekrutacyjnego”.
						</Rodo>
						<Image fluid={file.childImageSharp.fluid} />
						<h5> Przyszłe rekrutacje</h5>
						<p>
							Jeśli wyraża Pan/Pani zgodę na wykorzystywanie swoich danych osobowych na potrzeby przyszłych rekrutacji / procesów współpracy, prosimy
							o zamieszczenie w CV / Resume następującej klauzuli:
						</p>
						<Rodo>
							„Wyrażam zgodę na przetwarzanie moich danych osobowych przez Administratora – MWDK / Waś, Krajewski & Wspólnicy z siedzibą w Warszawie
							(00-400) przy ul. Nowy Świat 6/12 - dla celów przyszłych rekrutacji / procesów współpracy”.
						</Rodo>
						<p>Uprzejmie informujemy, że skontaktujemy się jedynie w wybranymi osobami.</p>
						<ButtonWrapper>
							{/* <Button accent className="readMore" to="/kariera#page">
								czytaj więcej...
							</Button> */}
							<AnchorLink className="readMore" to="/kariera#page">
								Czytaj więcej
								<img src={arrow} alt="" className="arrowBig" />
							</AnchorLink>
						</ButtonWrapper>
					</Text>
				</Content>
			</BackgroundImage>
		</>
	)
}

export default Career

const List = styled.ul`
	/* padding: 40px 0px 150px; */
	li {
		list-style-position: inside;
		text-indent: -1.5em;
		padding-left: 1.5em;
		line-height: 1.5;
	}
`
const Image = styled(Img)`
	display: none;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		display: block;
		margin-bottom: 30px;
	}
`

const Content = styled(Flex)`
	justify-content: flex-end;
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		flex-direction: column;
	}
`
const ButtonWrapper = styled(AnchorLink)`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	.readMore {
		margin-bottom: 20px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #306497;
		font-weight: 500;
		transition: 0.3s;
		&:hover .arrowBig {
			transform: translateX(1rem);
		}
		&:hover {
			/* border: 1px solid red; */
		}
		.arrowBig {
			margin-left: 1rem;
			transition: 0.3s;
			width: 25px !important;
			height: 25px !important;
		}
	}
`

const Text = styled.div`
	/* min-height: 450px; */

	width: 60%;
	padding: 50px 50px;
	background-color: ${(props) => props.theme.color.white.whiteRegular};
	h4 {
		text-align: center;
	}
	button {
		width: 200px;
	}
	/* text-align: center; */
	@media (max-width: ${(props) => props.theme.screen.lg}) {
		padding: 30px;
		width: 60%;
		button {
			margin: 0 auto;
		}
	}
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		padding: 30px;
		width: 100%;
	}

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		padding: 50px 15px;
		button {
			padding: 7px 20px;
		}
	}
`
const Rodo = styled.p`
	font-style: italic;
	text-align: left;
	font-size: ${(props) => props.theme.font.size.xxsmall};
`
