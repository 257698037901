import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Container, Flex } from '../../global'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'
import gsap from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import Tilt from 'react-vanilla-tilt'
import { Phone, Mail, Facebook, Linkedin, Instagram, Twitter } from 'react-feather'
import { employee } from '../../const'
import arrow from '../../../images/arrow.png'
const query = graphql`
	{
		allFile(filter: { relativePath: { regex: "/.*transparent.*/" }, extension: { eq: "png" } }, sort: { fields: name }) {
			nodes {
				dir
				relativePath
				name
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	}
`

const Team = () => {
	const { allFile } = useStaticQuery(query)
	const sortedFiles = allFile.nodes.sort(function (a, b) {
		const path1 = a.name.split('_')
		const path2 = b.name.split('_')
		return path1[0] - path2[0]
	})

	return (
		<TeamWrapper id="team">
			<Content>
				<h4>Zespół</h4>
				<p>
					Zespół Kancelarii MWDK / Waś, Krajewski & Wspólnicy tworzą specjaliści z różnych dziedzin prawa z wieloletnim doświadczeniem w obsłudze
					dużych i międzynarodowych podmiotów gospodarczych. Nasze działania ukierunkowane są na rezultat - dostarczamy efektywne, praktyczne i
					optymalne pod względem kosztów rozwiązania dla naszych Klientów.
				</p>
			</Content>
			<h5>Wspólnicy</h5>
			<TeamItems>
				{sortedFiles.map((item, index) => {
					const path = item.name.split('_')
					if (employee[index].team === 1) {
						const url =
							path.length >= 4 ? `zespol/${path[0]}_${path[1]}_${path[2]}_${path[3]}/#page` : `zespol/${path[0]}_${path[1]}_${path[2]}/#page`
						return (
							<ItemBig>
								<Circle to={url}>
									{/* {`zespol/${path[1]}${path[2]}/#page`} */}
									{/* {path[0]} */}
									<UserInfo>
										<p className="name">
											{employee && employee[index].name} {employee && employee[index].surname}
										</p>

										{employee && employee[index].title ? <p className="title">{employee[index].title}</p> : null}
									</UserInfo>
									<ImageContainer className="img">
										<Picture fluid={item.childImageSharp.fluid} />
									</ImageContainer>
								</Circle>
								<Description>
									<p>{employee && employee[index].content[0]}</p>
									<br />
									<AnchorLink className="readMore" to={url}>
										Czytaj więcej
										<img src={arrow} alt="" className="arrowBig" />
									</AnchorLink>
								</Description>
							</ItemBig>
						)
					}
				})}
			</TeamItems>
			<h5 className="gray">Współpracownicy</h5>
			<TeamItems>
				{sortedFiles.map((item, index) => {
					const path = item.name.split('_')
					if (employee[index].team === 2) {
						const url =
							path.length >= 4 ? `zespol/${path[0]}_${path[1]}_${path[2]}_${path[3]}/#page` : `zespol/${path[0]}_${path[1]}_${path[2]}/#page`
						if (employee[index].name === '') return
						return (
							<Item to={url}>
								{/* {`zespol/${path[1]}${path[2]}/#page`}
								{path[0]} */}

								<img src={arrow} className="arrow" />
								<UserInfo>
									<p className="name">
										{employee && employee[index].name} {employee && employee[index].surname}
									</p>

									{employee && employee[index].title ? <p className="title">{employee[index].title}</p> : null}
								</UserInfo>
								<ImageContainer>
									<Picture fluid={item.childImageSharp.fluid} />
								</ImageContainer>
							</Item>
						)
					}
				})}
			</TeamItems>
		</TeamWrapper>
	)
}

export default Team

const TeamWrapper = styled.section`
	position: relative;
	h5 {
		display: block;
		background-color: #306497;
		width: 250px;
		margin: 50px auto;
		text-align: center;
		padding: 4px 8px;
		font-weight: 100 !important;
		font-size: 18px;
		line-height: 22px;
		text-transform: uppercase;
		color: #ffffff;
		&.gray {
			background-color: #5a5749;
		}
	}
`
const UserInfo = styled.div`
	/* width: 200px; */
	position: absolute;
	bottom: 1rem;
	right: -3rem;
	z-index: 10;

	p {
		min-width: 140px;
		display: block;
		font-weight: 300 !important;
		font-size: 10px;
		color: #fff;
		/* line-height: 12px; */
		padding: 4px 4px;
		margin: 0;
		text-align: center;
		text-transform: uppercase;
		&:first-child {
			margin-left: 1rem;
		}
		&:last-child {
			margin-left: -1rem;
		}
	}
	.name {
		background-color: #306497;
	}
	.title {
		background-color: #5a5749;
	}

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		/* left: 0; */
		right: 0;
		left: 0;
		margin: 0 auto;
		width: 80%;
		p {
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin: 0;
			}
		}
	}
`
const Description = styled.div`
	position: relative;
	/* border: 1px solid red; */
	padding: 0 15px;
	.readMore {
		margin-top: 30px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #306497;
		font-weight: 500;
		transition: 0.3s;
		&:hover .arrowBig {
			transform: translateX(1rem);
		}
		&:hover {
			/* border: 1px solid red; */
		}
	}
	.arrowBig {
		margin-left: 1rem;
		transition: 0.3s;
	}
	p {
		text-align: left;
		height: 180px;
	}
`
const ImageContainer = styled.div`
	width: 100%;
	background-repeat: no-repeat;
	border-radius: 50%;
	overflow: hidden;
	background: linear-gradient(157.99deg, #ebebeb5c 8.66%, rgba(217, 217, 217, 0) 85.61%);
	filter: grayscale(0.4);
	&:hover {
		filter: grayscale(0);
	}
`
const Picture = styled(BackgroundImage)`
	height: 350px;
	width: 100%;
	background-position: center 20%;
	background-repeat: no-repeat;
	background-size: 90% !important;
	/* border-radius: 50%; */
	/* border: 1px solid red; */
	box-shadow: 4px 4px 6px 2px rgba(66, 68, 90, 0.57);
`
const TeamItems = styled(Flex)`
	margin: 60px auto 100px;
	width: 90%;
	justify-content: center;
	overflow: hidden;
	flex-wrap: wrap;
	/* border: 1px solid red; */
	@media (max-width: ${(props) => props.theme.screen.xl}) {
		width: 90%;
	}
	@media (max-width: ${(props) => props.theme.screen.lg}) {
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
	}

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		flex-direction: column;
	}
`

const Item = styled(AnchorLink)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 230px;
	height: 230px;
	margin: 20px;
	border-radius: 50%;

	filter: grayscale(0.2);
	.arrow {
		position: absolute;
		right: 0px;
		top: 10px;
		z-index: 10;
		opacity: 0;
		transition: 0.3s;
	}
	&:hover > .arrow {
		opacity: 1;
		transform: translateX(-10px);
	}
	transition: ${(props) => props.theme.transition.primary};
	&:hover {
		filter: grayscale(0);

		box-shadow: 0px 0px 7px -2px #3131315c;
	}

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		/* width: 90%; */
		/* border: 1px solid red; */
		width: 280px;
		height: 280px;
	}
`
const ItemBig = styled.div`
	position: relative;
	width: 400px;
	/* height: 500px; */
	margin: 20px;
	/* border: 1px solid red; */
	/* background-color: white; ; */
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		/* width: 80%; */
		/* border: 1px solid red; */
		width: 350px;
		margin: 0px 0px 50px 0;
	}
`

const Circle = styled(AnchorLink)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 350px;
	height: 350px;
	margin: 20px;
	border-radius: 50%;
	filter: grayscale(0.2);
	transition: ${(props) => props.theme.transition.primary};
	&:hover {
		filter: grayscale(0);
		box-shadow: 0px 0px 7px -2px #3131315c;
	}

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		/* width: 80%; */
		/* border: 1px solid red; */
		margin: 0;
	}
`

const Content = styled(Container)`
	padding-bottom: 30px;

	h4 {
		${(props) => props.theme.color.background};
		color: ${(props) => props.theme.color.primary};
		text-align: center;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		/* background-color: rgba(0, 0, 0, 0.1); */
		z-index: -1;
	}
	p {
		width: 90%;
		margin: 0 auto;
		text-align: justify;
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			width: 100%;
		}
	}
	/* p {
    text-align: left;
    margin: 0 auto;
    width: 80%;
    z-index: 1;
    @media (max-width: ${(props) => props.theme.screen.xs}) {
      width: 100%;
    }
  } */
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		padding: 50px 10px;
		min-height: 300px;
	}
`
