import React from 'react'
import styled from 'styled-components'
import { Flex, Button } from '../global'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Item = ({ title, icon, description, url, size = null }) => {
	let activeLink = url && url.length > 0 ? `specjalizacje/${url}#page` : null
	return (
		<Square to={activeLink} size={size} className={url && 'link'}>
			<img src={require('../../images/icons3/' + icon)} alt="specjalizacja" />
			<h5 size={size}>{title}</h5>

			{!size ? <p>{description}</p> : null}
			{!size ? <ReadMore>Czytaj więcej...</ReadMore> : null}
		</Square>
	)
}

export default Item

const Square = styled(AnchorLink)`
	display: flex;
	position: relative;
	border-radius: 5px;
	flex-direction: column;
	width: 21%;
	margin: 15px 20px;
	background-color: ${(props) => props.theme.color.white.whiteRegular};
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	align-items: center;
	justify-content: flex-start;
	min-height: 420px;
	${(props) => (props.size ? 'min-height: 210px;' : null)}
	${(props) => (props.size ? 'width:15%;' : null)}
	cursor: default;
	&.link {
		cursor: pointer;
		border: 1px solid #14293ea7;
	}
	overflow: hidden;
	/* border: 1px solid red; */
	h5 {
		font-weight: ${(props) => props.theme.font.weight.semiBold};
		/* color: ${(props) => props.theme.color.white.whiteRegular}; */
		text-align: center;
		text-transform: uppercase;
		font-size: ${(props) => props.theme.font.size.xxsmall};
		${(props) => (props.size ? 'margin-top:5px;font-size:11px;padding:15px 5px 0 5px;' : null)}
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			font-size: ${(props) => props.theme.font.size.xxxsmall};
		}
	}
	img {
		filter: contrast(1.1) grayscale(0.1);
		width: 100%;
		margin-top: 0px;
		padding: 0;
		/* width: 40px; */
		/* height: 35px; */
		max-height: 150px;
		height: 150px;
		overflow: hidden;
		transition: ${(props) => props.theme.transition.secondary};
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			/* height: 25px; */
		}
	}
	p {
		margin: 0;
		padding: 10px 40px;
		font-size: ${(props) => props.theme.font.size.xxsmall};
		text-align: left;
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			/* display: none; */
			text-align: center;
		}
	}
	transition: ${(props) => props.theme.transition.secondary};
	&:hover {
		/* background-color: ${(props) => props.theme.color.white.whiteDark}; */
		/* filter: invert(0.9) brightness(1); */
		/* ${(props) => (!props.size ? 'cursor: default !important' : 'cursor: pointer;')} */
		//cursor: default !important;
		/* border: 1px solid ${(props) => props.theme.color.black.transparent}; */
		/* transform: scale(1.05); */
		/* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px; */
		box-shadow: 1px 1px 10px -4px ${(props) => props.theme.color.black.grey};
		&:hover > img {
			${(props) => (!props.size ? 'transform: scale(1.1);margin-bottom: 10px;' : null)};
		}
		&:hover > span {
			transform: translateX(-100px);
		}
	}

	@media (max-width: ${(props) => props.theme.screen.lg}) {
		/* min-height: 400px; */
		width: 22%;
		/* padding: 10px 40px; */
		margin: 15px 10px;
	}

	@media (max-width: ${(props) => props.theme.screen.md}) {
		/* min-height: 400px; */
		width: 30%;
	}

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		width: 42%;
		margin: 15px;
	}

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		width: 90%;
		margin: 10px 10px;
	}
`

const ReadMore = styled.span`
	position: relative;
	display: block;
	padding: 8px 10px;
	text-transform: uppercase;
	font-size: ${(props) => props.theme.font.size.xxxxsmall};
	font-weight: ${(props) => props.theme.font.weight.medium};
	position: absolute;
	bottom: 10px;
	right: -90px;
	transition: all ${(props) => props.theme.transition.secondary};
	color: ${(props) => props.theme.color.accent};
	${(props) => (props.size ? 'border:1px solid red' : null)}
	/* border: 1px solid ${(props) => props.theme.color.white.whiteDark}; */
  /* border-radius: 15px; */
  /* background-color: ${(props) => props.theme.color.accent}; */
  /* ::before {
    content: "";
    left: -150px;
    top: 0;
    position: absolute;
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 0 0 150px 150px;
    border-color: transparent transparent ${(props) => props.theme.color.accent}
      transparent;
  } */

  @media (max-width: ${(props) => props.theme.screen.xs}) {
		display: none;
	}
`
