import React from 'react'
import styled from 'styled-components'

const NonAvailable = () => {
	return (
		<Title>
			{/* <h4>Aktualności</h4> */}

			<h4>Strona chwilowo niedostępna. Przepraszamy za utrudnienia.</h4>
		</Title>
	)
}

export default NonAvailable

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	line-height: 1.6;
	h4 {
		font-size: 18px;
		font-weight: 300;
	}
`
