import React from 'react'
import { isIE } from 'react-device-detect'
import styled from 'styled-components'
import { Section, Container, Flex, Button } from '../../global'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
// import about from '../../images/about.jpg'
import { EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
// import 'swiper/css/pagination'
// import 'swiper/css/navigation'
import { Pagination, Navigation } from 'swiper'
import { articles } from './data'
import arrow from '../../../images/arrow.png'
const News = () => {
	const reversedArticles = articles.reverse()
	return (
		<Section id="news">
			{/* <h4>Aktualności</h4> */}
			<Container>
				<NewsWrapper>
					<Swiper
						pagination={{
							type: 'fraction',
						}}
						navigation={true}
						slidesPerView={1}
						// spaceBetween={10}
						modules={[Pagination, Navigation]}
						className="mySwiper"
					>
						{articles &&
							reversedArticles.map((item, index) => {
								let url = '/artykuly/' + item.url + '/#page'
								let short = item.content[0].length >= 400 ? item.content[0].substring(0, 399).concat(' ...') : item.content[0]
								return (
									<SwiperSlide>
										<Article>
											<Title>
												<h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
											</Title>
											<Content>
												<p dangerouslySetInnerHTML={{ __html: short }}></p>

												{item.type && item.type === 'pdf' ? (
													<a className="readMore" href={require(`./pdf/${item.url}.pdf`)} target="blank">
														Czytaj więcej
														<img src={arrow} alt="" className="arrowBig" />
													</a>
												) : (
													<AnchorLink className="readMore" to={url}>
														Czytaj więcej
														<img src={arrow} alt="" className="arrowBig" />
													</AnchorLink>
												)}
											</Content>
										</Article>
									</SwiperSlide>
								)
							})}
					</Swiper>
				</NewsWrapper>
			</Container>
		</Section>
	)
}

export default News

const NewsWrapper = styled(Flex)`
	height: auto;
	justify-content: space-between;
	align-items: flex-start;
	/* border: 1px solid red; */
	/* height: 500px; */
	/* border: 1px solid red; */
	.mySwiper {
		border-radius: 5px;
		.swiper {
			width: 100%;
			height: 100%;
		}
		.swiper-button-next,
		.swiper-button-prev {
			color: black;
			/* border: 1px solid red; */
			&::after,
			&::before {
				font-size: 17px;
			}
			@media (max-width: ${(props) => props.theme.screen.sm}) {
				display: none;
			}
		}
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			/* background: #fff; */
			/* Center slide text vertically */
			/* display: flex;
			justify-content: center;
			align-items: center; */
		}

		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
`
const Article = styled.article`
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		flex-direction: column;
	}
	h4 {
		font-size: 16px;
	}
`
const Title = styled.div`
	display: flex;
	align-items: center;
	border-right: 1px solid ${(props) => props.theme.color.white.whiteDark};
	margin-right: 30px;
	width: 30%;
	line-height: 1.6;
	margin-left: 2rem;
	padding: 2rem;
	h4 {
		text-align: left;
		text-transform: capitalize;
		font-weight: ${(props) => props.theme.font.weight.semiBold};
		&::before,
		&::after {
			display: none;
		}
	}

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		/* border: 1px solid red; */
		border-right: 0px;
		width: 100%;
		/* margin: 10px; */
		/* margin-left: -2rem; */
		h4 {
			text-align: center;
			margin: 0;
			padding: 0;
			width: 100%;
		}
	}
`
const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	column-count: 2;
	column-gap: 40px;
	padding: 2rem;
	margin-right: 2rem;
	@media (max-width: ${(props) => props.theme.screen.md}) {
		column-count: 1;
		column-gap: 40px;
	}
	p {
		margin-top: 0;
	}
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		margin: 0;
		width: 100%;
	}
	.readMore {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #306497;
		font-weight: 500;
		transition: 0.3s;

		@media (max-width: ${(props) => props.theme.screen.sm}) {
			justify-content: center;
			margin-bottom: 25px;
		}
		&:hover .arrowBig {
			transform: translateX(1rem);
		}
		&:hover {
			/* border: 1px solid red; */
		}
		.arrowBig {
			margin-left: 1rem;
			transition: 0.3s;
			width: 25px !important;
			height: 25px !important;
		}
	}
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		margin: 2rem 0;
		justify-content: center;
	}
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		justify-content: center;
	}
`
const Item = styled.div`
	border: 1px solid green;
	width: 45%;
	padding: 20px;
`
