import React from 'react'
import { isIE } from 'react-device-detect'
import styled from 'styled-components'
import { Section, Container, Flex, Button } from '../global'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import about from '../../images/about.jpg'
const query = graphql`
	query {
		file(relativePath: { eq: "about.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 500, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

const About = () => {
	const { file } = useStaticQuery(query)

	return (
		<Section id="about">
			{/* <SvgPolygon
        xmlns='http://www.w3.org/2000/svg'
        width='1471.06'
        height='1007.413'
        viewBox='0 0 1571.06 1107.413'>
        <defs></defs>
        <path
          id='polygon_shape'
          data-name='polygon shape'
          d='M676.876,15.623a91,91,0,0,1,72.247,0l585.345,253.16A91,91,0,0,1,1378.221,395.9l-198.69,364.018a91,91,0,0,1-79.876,47.4H326.345a91,91,0,0,1-79.876-47.4L47.779,395.9A91,91,0,0,1,91.531,268.783Z'
          transform='translate(181.608) rotate(13)'
        />
      </SvgPolygon> */}
			<Container>
				<h4>O Kancelarii</h4>
				<article>
					<Content>
						{isIE ? (
							<Column>
								<IEContainer>
									<img src={about} alt="about" />
								</IEContainer>
							</Column>
						) : (
							<Column>
								<ImageWrapper>
									<Image fluid={file.childImageSharp.fluid} alt="Kancelaria" />
								</ImageWrapper>
							</Column>
						)}

						<Column>
							{/* <p>
                Nasza Kancelaria specjalizuje się między innymi w prawie
                korporacyjnym (w tym transakcjach M&A), prawie rynku
                kapitałowego, prawie bankowym, prawie nieruchomości i robotach
                budowlanych, negocjacji umów, prawie energetycznym, prawie
                karnym, prawie pracy oraz prawie zamówień publicznych. Ponadto
                posiadamy wieloletnie doświadczenie w szeroko pojętym prawie
                zbrojeniowym, w tym w offsecie przemysłowym realizowanym na
                podstawie ustawy o niektórych umowach zawieranych w związku z
                realizacją zamówień o podstawowym znaczeniu dla bezpieczeństwa
                państwa, a nadto w prawie kolejowym, w tym w rozstrzyganiu
                takich kwestii jak dopuszczalność i możliwość różnicowania cen
                biletów kolejowych w zależności od kanału dystrybucji (tematyka
                obejmująca swoim zakresem szeroko pojęte prawo kolejowe, prawo
                cywilne oraz prawo antymonopolowe).
              </p> */}
							{/* <h5>Lorem ipsum dolor sit.</h5> */}
							<p>
								Jesteśmy dynamicznie rozwijającym się zespołem radców prawnych i adwokatów skupionych na kompleksowej obsłudze Klientów biznesowych i
								indywidualnych. To co nas wyróżnia, to nie tylko najwyższa jakość świadczonych usług, ale również czas reakcji. Z uwagi bowiem na
								wysokie tempo życia gospodarczego i potrzeby naszych Klientów, w sprawach pilnych jesteśmy do dyspozycji 24h. Wiemy bowiem, że czas
								reakcji jest często kluczowy dla powodzenia wielu inwestycji i zamierzeń.
							</p>
							<p>
								<strong>
									Podstawą naszej pracy jest dogłębne zrozumienie potrzeb Klientów, dzięki czemu podejmowane czynności zawsze dopasowane są do Ich
									indywidualnych wymagań oraz specyfiki zleconych zadań.
								</strong>
							</p>
							<p>
								Nasi Klienci to zarówno duże, często międzynarodowe podmioty gospodarcze, w tym polskie i zagraniczne spółki, ale także osoby
								fizyczne. Dodatkowo świadczymy usługi pro bono Fundacji Zostaw Serce na Ziemi zajmującej się sprowadzeniem do Polski urządzeń
								podtrzymujących pracę serca przed przeszczepem.
							</p>
						</Column>
					</Content>
				</article>
			</Container>
		</Section>
	)
}

export default About
const SvgPolygon = styled.svg`
	position: absolute;
	fill: ${(props) => props.theme.color.black.transparentLight};
	top: -50px;
	left: -400px;
	z-index: 0;
`
const IEContainer = styled.div`
	max-width: 100%;
	width: 100%;
	height: 200px;
	min-height: 200px;
	border: 1px solid green;
`
const ImageWrapper = styled.div`
	width: 100%;
	/* height: 200px; */
`
const Image = styled(Img)`
	margin: 20px 0;
	max-height: 365px;
	width: 100%;
	max-width: 100%;
	border-radius: 5px;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		width: 100%;
		margin: 20px 0;
	}
`
const Content = styled(Flex)`
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		flex-direction: column;
	}
`
const Column = styled(Flex)`
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-basis: 48%;
	z-index: 1;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		width: 100%;
	}
`
