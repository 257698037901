import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import styled from "styled-components";
const Cookies = () => {
  let cookies = useRef(null);

  const setCookies = () => {
    localStorage.setItem("mwdk_cookies", true);
    gsap.to(cookies.current, {
      duration: 0.6,
      y: 200,
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("mwdk_cookies")) {
      gsap.to(cookies.current, {
        delay: 5,
        duration: 0.8,
        y: 0,
      });
    }
  }, []);
  return (
    <CookiesWrapper ref={cookies}>
      <p>
        Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
        poziomie. Dalsze korzystanie ze strony oznacza, że zgadasz się na ich
        używanie.
      </p>
      <button className='button' onClick={() => setCookies()}>
        zgoda
      </button>
    </CookiesWrapper>
  );
};

export default Cookies;

const CookiesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.black.transparentMedium};
  z-index: 50;
  color: #fff;
  font-size: 0.7rem;
  padding: 0 1rem;
  font-weight: 200;
  transform: translateY(200px);
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  p {
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.color.white.whiteRegular};
  }
  button {
    padding: 5px 15px;
    text-transform: uppercase;
    margin-left: 10px;
    font-size: ${(props) => props.theme.font.size.xxxxsmall};
    font-weight: ${(props) => props.theme.font.weight.bold};
    border-radius: 25px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #fff;
    }
  }
`;
